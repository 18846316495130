<script setup lang="ts">
    import { computed, ref } from 'vue';
    import CheckboxField from '../form-components/CheckboxField.vue';
    import FormField from '../form-components/FormField.vue';
    import { useForm } from 'vee-validate';
    import { boolean, object, string } from 'zod';
    import { toTypedSchema } from '@vee-validate/zod';
    import { companyCheckUidApi, customerExistsApi, signupApi } from '@containex/portal-backend-api-client';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import { HttpStatusCode } from 'axios';
    import { SalutationType } from '@/types/Salutation';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { useMarketQuery } from '@/composables/market';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useI18n } from 'vue-i18n';
    import { useMemoize } from '@vueuse/core';
    import Button from 'primevue/button';
    import Dropdown from 'primevue/dropdown';
    import InputText from 'primevue/inputtext';
    import RadioButtonField from '@/components/form-components/RadioButtonField.vue';
    import { httpClient } from '@/common/api/http-client';

    /**
     * Future ToDos:
     * - min/max lengths for fields
     *
     * improvement ideas:
     * - custom phone number field + validation
     * - UID validation based on chosen country (regex, without 3rd party)
     */

    const { availableMarkets, market } = useMarketQuery();

    const { t, locale } = useI18n();

    const marketCode = computed(() => market?.value?.code.toLowerCase() ?? 'at');
    // TODO CPP-665: support i18n in the link
    const privacyPolicyUrl = computed(() => `https://www.containex.com/${marketCode.value}/de/datenschutz`);
    const agbUrl = computed(() => `https://www.containex.com/${marketCode.value}/de/nutzungsbedingungen`);

    const customerExistsCheck = useMemoize(async (value: string) => {
        return await customerExistsApi.customerExists(httpClient, { email: value });
    });

    const companyUidCheck = useMemoize(async (value: string) => {
        return await companyCheckUidApi.checkCompanyUid(httpClient, { uid: value });
    });

    const schema = object({
        email: string()
            .email(t('ERROR.INVALID_EMAIL'))
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    const response = await customerExistsCheck(value);
                    const state = response.data.state;

                    return state === 'still-free' || state !== 'exists-just-login';
                },
                {
                    message: t('ERROR.CUSTOMER_ALREADY_EXISTS.JUST_LOGIN'),
                }
            )
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    const response = await customerExistsCheck(value);
                    const state = response.data.state;

                    return state === 'still-free' || state !== 'exists-contact-admin';
                },
                {
                    message: t('ERROR.CUSTOMER_ALREADY_EXISTS.CONTACT_ADMIN'),
                }
            )
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    const response = await customerExistsCheck(value);
                    const state = response.data.state;

                    return state === 'still-free' || state !== 'exists-contact-containex';
                },
                {
                    message: t('ERROR.CUSTOMER_ALREADY_EXISTS.CONTACT_CONTAINEX'),
                }
            ),
        jobTitle: string().trim().min(1).optional(),
        firstName: string().trim().min(1),
        lastName: string().trim().min(1),
        city: string().trim().min(1),
        postalCode: string().trim().min(1),
        addressLine1: string().trim().min(1),
        companyName: string().trim().min(1),
        uid: string()
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    const response = await companyUidCheck(value);
                    const state = response.data.state;

                    return state === 'still-free' || state !== 'has-admin';
                },
                {
                    message: t('ERROR.COMPANY_UID_TAKEN.CONTACT_ADMIN'),
                }
            )
            .refine(
                async (value) => {
                    if (value === '') {
                        return true;
                    }

                    const response = await companyUidCheck(value);
                    const state = response.data.state;

                    return state === 'still-free' || state !== 'no-admin';
                },
                {
                    message: t('ERROR.COMPANY_UID_TAKEN.CONTACT_CONTAINEX'),
                }
            ),
        countryCode: string().toLowerCase(),
        phoneBusiness: string().trim().min(1),
        phoneMobile: string().trim().min(1),
        salutation: string().default(SalutationType.MALE),
        acceptDsgvo: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.DSGVO_TEXT_VALIDATION_TEXT'),
            }),
        acceptTermsOfService: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.TOS_TEXT_VALIDATION_TEXT'),
            }),
        acceptGeneralTerms: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.AGB_TEXT_VALIDATION_TEXT'),
            }),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [jobTitle] = defineField('jobTitle');
    const [firstName] = defineField('firstName');
    const [lastName] = defineField('lastName');
    const [city] = defineField('city');
    const [addressLine1] = defineField('addressLine1');
    const [postalCode] = defineField('postalCode');
    const [companyName] = defineField('companyName');
    const [uid] = defineField('uid');
    const [phoneBusiness] = defineField('phoneBusiness');
    const [phoneMobile] = defineField('phoneMobile');
    const [salutation] = defineField('salutation');
    const [countryCode] = defineField('countryCode');
    const [acceptDsgvo] = defineField('acceptDsgvo');
    const [acceptTermsOfService] = defineField('acceptTermsOfService');
    const [acceptGeneralTerms] = defineField('acceptGeneralTerms');
    const [email] = defineField('email', {
        validateOnModelUpdate: false,
    });

    const countryOptions = ref(availableMarkets.value);

    const { errorToastOptions, addToast } = useGlobalToast();

    const onSubmit = handleSubmit(async (values) => {
        const response = await signupApi.signup(httpClient, {
            firstName: values.firstName,
            lastName: values.lastName,
            addressLine1: values.addressLine1,
            city: values.city,
            companyName: values.companyName,
            countryCode: values.countryCode,
            email: values.email,
            phoneBusiness: values.phoneBusiness,
            phoneMobile: values.phoneMobile,
            postalCode: values.postalCode,
            salutation: values.salutation,
            uid: values.uid,
            jobTitle: values.jobTitle,
            language: new Intl.Locale(locale.value).language,
            dsgvoAccepted: values.acceptDsgvo,
            generalTermsAccepted: values.acceptGeneralTerms,
        });

        if (response.status === HttpStatusCode.Ok) {
            await router.push({ name: Navigation.SignupSuccess });
        } else {
            addToast(errorToastOptions);
        }
    });

    async function navigateToSignupSelection(): Promise<void> {
        await router.push({ name: Navigation.SignupSelection });
    }
</script>

<template>
    <form class="container" @submit.prevent="onSubmit">
        <div>
            <p class="text-base-bold-line-height-auto">{{ t('SIGNUP.PERSONAL_INFORMATION') }}</p>
            <div class="salutation">
                <p>{{ t('SIGNUP.SALUTATION') }}</p>
                <div class="salutation-selection">
                    <RadioButtonField
                        v-model="salutation"
                        :value="SalutationType.MALE"
                        :label="t('FORM.SALUTATION_MALE')"
                        :error-message="errors.salutation"
                    />
                    <RadioButtonField
                        v-model="salutation"
                        :value="SalutationType.FEMALE"
                        :label="t('FORM.SALUTATION_FEMALE')"
                        :error-message="errors.salutation"
                    />
                </div>
            </div>
            <FormField :label="t('FORM.FIRST_NAME')" :error-message="errors.firstName">
                <InputText
                    v-model="firstName"
                    class="full-width"
                    data-testid="signup-form-firstname"
                    :invalid="hasValidationError(errors.firstName)"
                />
            </FormField>
            <FormField :label="t('FORM.LAST_NAME')" :error-message="errors.lastName">
                <InputText
                    v-model="lastName"
                    class="full-width"
                    data-testid="signup-form-lastname"
                    :invalid="hasValidationError(errors.lastName)"
                />
            </FormField>
            <FormField :label="t('FORM.POSITION')" :error-message="errors.jobTitle">
                <InputText
                    v-model="jobTitle"
                    class="full-width"
                    data-testid="signup-form-job-title"
                    :invalid="hasValidationError(errors.jobTitle)"
                />
            </FormField>
            <FormField :label="t('FORM.EMAIL')" :error-message="errors.email">
                <InputText
                    v-model="email"
                    class="full-width"
                    data-testid="signup-form-email"
                    :invalid="hasValidationError(errors.email)"
                />
            </FormField>
        </div>
        <div>
            <p class="text-base-bold-line-height-auto sectionHeader">{{ t('SIGNUP.COMPANY_DATA') }}</p>
            <FormField class="sectionFirstElement" :label="t('FORM.COMPANY_NAME')" :error-message="errors.companyName">
                <InputText
                    v-model="companyName"
                    class="full-width"
                    data-testid="signup-form-company"
                    :invalid="hasValidationError(errors.companyName)"
                />
            </FormField>
            <FormField :label="t('FORM.UID')" :error-message="errors.uid">
                <InputText
                    id="uid"
                    v-model="uid"
                    class="full-width"
                    data-testid="signup-form-uid"
                    :invalid="hasValidationError(errors.uid)"
                />
            </FormField>
            <FormField :label="t('FORM.ADDRESS_LINE_1')" :error-message="errors.addressLine1">
                <InputText
                    v-model="addressLine1"
                    class="full-width"
                    data-testid="signup-form-address-line-1"
                    :invalid="hasValidationError(errors.addressLine1)"
                />
            </FormField>
            <div class="address-split-container">
                <FormField class="plz-input" :label="t('FORM.POSTAL_CODE')" :error-message="errors.postalCode">
                    <InputText
                        v-model="postalCode"
                        class="full-width"
                        data-testid="signup-form-postal-code"
                        :invalid="hasValidationError(errors.postalCode)"
                    />
                </FormField>
                <FormField class="city-input" :label="t('FORM.CITY')" :error-message="errors.city">
                    <InputText
                        v-model="city"
                        class="full-width"
                        data-testid="signup-form-city"
                        :invalid="hasValidationError(errors.city)"
                    />
                </FormField>
            </div>
            <FormField :label="t('FORM.COUNTRY')" :error-message="errors.countryCode">
                <Dropdown
                    v-model="countryCode"
                    class="full-width"
                    data-testid="signup-form-country"
                    :options="countryOptions"
                    option-label="name"
                    option-value="code"
                    :invalid="hasValidationError(errors.countryCode)"
                />
            </FormField>
            <div class="telephone-split-container">
                <div class="business-telephone-container">
                    <FormField
                        class="full-width"
                        :label="t('FORM.PHONE_BUSINESS')"
                        :error-message="errors.phoneBusiness"
                    >
                        <InputText
                            v-model="phoneBusiness"
                            class="full-width"
                            data-testid="signup-form-phone-business"
                            :invalid="hasValidationError(errors.phoneBusiness)"
                        />
                    </FormField>
                    <p class="small-secondary-text">{{ t('FORM.INCLUSIVE_COUNTRY_CODE') }}</p>
                </div>
                <div class="private-telephone-container">
                    <FormField class="full-width" :label="t('FORM.PHONE_MOBILE')" :error-message="errors.phoneMobile">
                        <InputText
                            v-model="phoneMobile"
                            class="full-width"
                            data-testid="signup-form-phone-mobile"
                            :invalid="hasValidationError(errors.phoneMobile)"
                        />
                    </FormField>
                    <p class="small-secondary-text">{{ t('FORM.INCLUSIVE_COUNTRY_CODE') }}</p>
                </div>
            </div>
        </div>
        <CheckboxField
            v-model="acceptDsgvo"
            class="margin-top"
            data-testid="signup-form-checkbox-dsgvo"
            :binary="true"
            :invalid="hasValidationError(errors.acceptDsgvo)"
            :error-message="errors.acceptDsgvo"
        >
            {{ t('FORM.DSGVO_TEXT.PRE') }}
            <a :href="privacyPolicyUrl" class="privacy-policy-link" target="_blank">
                {{ t('FORM.DSGVO_TEXT.PRIVACY_POLICY') }}
            </a>
            {{ t('FORM.DSGVO_TEXT.POST') }}
        </CheckboxField>
        <CheckboxField
            v-model="acceptGeneralTerms"
            data-testid="signup-form-checkbox-terms"
            :binary="true"
            :invalid="hasValidationError(errors.acceptGeneralTerms)"
            :error-message="errors.acceptGeneralTerms"
        >
            {{ t('FORM.AGB_TEXT.PRE') }}
            <a :href="agbUrl" class="privacy-policy-link" target="_blank">
                {{ t('FORM.AGB_TEXT.AGB') }}
            </a>
            {{ t('FORM.AGB_TEXT.POST') }}
        </CheckboxField>
        <CheckboxField
            v-model="acceptTermsOfService"
            data-testid="signup-form-checkbox-tos"
            :binary="true"
            :invalid="hasValidationError(errors.acceptTermsOfService)"
            :error-message="errors.acceptTermsOfService"
        >
            {{ t('FORM.TOS_TEXT') }}
        </CheckboxField>
        <div class="margin-top button-container">
            <Button
                type="submit"
                class="button"
                data-testid="signup-form-register-button"
                :label="t('SIGNUP.CREATE_ACCOUNT_BUTTON')"
            />
            <Button class="margin-top" :label="t('SIGNUP.BACK')" text @click="navigateToSignupSelection" />
        </div>
    </form>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    @include main.for-breakpoint-md {
        .salutation {
            display: flex;
            align-items: center;

            p {
                margin-right: main.$spacing-3;
            }
        }

        .telephone-split-container {
            display: flex;
        }

        .business-telephone-container {
            margin-right: main.$spacing-3;
            width: 50%;
        }

        .private-telephone-container {
            width: 50%;
        }

        .button {
            justify-content: center;
            margin-right: main.$spacing-5;
        }

        .container .button-container {
            display: unset;
        }
    }

    .container {
        background: main.$vt-c-white;
        display: flex;
        flex-direction: column;
    }

    .salutation-selection {
        display: flex;

        > * {
            margin-right: main.$spacing-3;
        }
    }

    .address-split-container {
        display: flex;
    }

    .plz-input {
        margin-right: main.$spacing-3;
        width: 30%;
    }

    .city-input {
        width: 70%;
    }

    .small-secondary-text {
        font-size: main.$font-size-sm;
        color: main.$color-secondary;
        margin: 0;
    }

    .privacy-policy-link {
        color: main.$ctx-primary-color;
        text-decoration: none;
    }

    .margin-top {
        margin-top: main.$spacing-5;
    }

    .strikethrough {
        text-decoration: line-through;
    }

    .sectionHeader {
        margin-top: main.$spacing-7;
    }

    .sectionFirstElement {
        padding-top: 0;
    }

    .button-container {
        display: flex;
        flex-direction: column;
    }
</style>
