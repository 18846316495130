<script setup lang="ts">
    import SignupForm from '@/components/signup/SignupForm.vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
</script>

<template>
    <div class="signup-form-container">
        <p class="text-3xl-bold-line-height-auto">{{ t('SIGNUP.TITLE') }}</p>
        <SignupForm />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    @include main.for-breakpoint-md {
        .signup-form-container {
            padding: main.$spacing-6;
        }
    }

    .signup-form-container {
        display: flex;
        row-gap: main.$spacing-4;
        flex-direction: column;
        background: main.$vt-c-white;

        p {
            margin-bottom: 0;
        }
    }
</style>
